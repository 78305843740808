<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="0"
      elevate-on-scroll
      height="80"
      color="primary"
    >
      <v-spacer />

      <div>
        <v-tabs
          optional
          background-color="transparent"
        >
          <v-tab
            v-if="['people/115801262928546540765', 'people/112533603349370662391'].includes(userToCheck)"
            class="font-weight-bold"
            min-width="42"
            text
          >
            <v-select
              v-model="selectedUser"
              :items="contactsArr"
              label="Switch user"
              item-text="fullName"
              item-value="resourceName"
              outlined
              class="pt-7"
              dense
              dark
              @change="userSwitch()"
            />
          </v-tab>
          <v-tab
            class="font-weight-bold"
            min-width="42"
            text
            @click="refreshData()"
          >
            <v-icon
              color="white"
            >
              mdi-refresh
            </v-icon>
          </v-tab>
          <v-tab
            v-for="(obj, i) in items"
            :key="i"
            :to="{ name: obj.to }"
            :exact="obj.to === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            <span
              :style="obj.to === $route.name ? 'color: white; border-bottom: 2px solid rgb(110,182,64);' : 'color: white; padding-bottom: 3px'"
            >{{ obj.text }}</span>
          </v-tab>
        </v-tabs>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'HomeAppBar',

    data: () => ({
      userToCheck: '',
      drawer: false,
      items: [
        {
          text: 'Home',
          to: 'Home',
        },
        {
          text: 'Team',
          to: 'Contacts',
        },
        {
          text: 'Card',
          to: 'Qr',
        },
      ],
      selectedUser: {},
    }),
    computed: {
      ...mapState('app', ['contactsArr', 'vibeUser']),
    },
    created () {
      this.userToCheck = localStorage.vibeUser ? JSON.parse(localStorage.vibeUser).resourceName : ''
    },
    methods: {
      ...mapActions('app', [
        'vibeUserWrite', 'contacts',
      ]),
      userSwitch () {
        const selectedUserLocal = this.contactsArr.find(x => x.resourceName === this.selectedUser)
        this.vibeUserWrite(selectedUserLocal)
      },
      refreshData () {
        this.$gapi.getGapiClient().then(x => x.client.people.people.get({
          resourceName: 'people/me',
          personFields: 'names,emailAddresses,phoneNumbers,addresses,organizations,metadata',
        }).then(res => {
          const resLocal = JSON.parse(res.body)

          this.selectedUser = resLocal.resourceName

          if (!localStorage.signatureBgColor) localStorage.signatureBgColor = '#440099'

          if (!localStorage.logoColor) localStorage.logoColor = '#6db640'

          this.$vuetify.theme.themes.light.signatureBg = localStorage.signatureBgColor || '#440099'
          this.$vuetify.theme.themes.light.logo = localStorage.logoColor || '#6db640'

          this.$gapi.getGapiClient()
            .then(x => x.client.people.people.listDirectoryPeople({
              readMask: 'names,occupations,locales,organizations,phoneNumbers,emailAddresses',
              sources: 'DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE',
              mergeSources: 'DIRECTORY_MERGE_SOURCE_TYPE_CONTACT',
            })
              .then(res => {
                const resDirectoryLocalTemp = JSON.parse(res.body)
                const resDirectoryLocal = resDirectoryLocalTemp ? resDirectoryLocalTemp.people.filter((x) => x.names && x.emailAddresses) : []
                localStorage.vibeDirectory = JSON.stringify(resDirectoryLocal)

                const loggedInUser = resDirectoryLocal.find(x => x.resourceName === resLocal.resourceName)
                localStorage.vibeUser = JSON.stringify(loggedInUser)
                this.saveVibeUser(loggedInUser)

                this.saveVibeDirectory(resDirectoryLocal)
              }))
            .catch(() => {
              localStorage.lastUrl = this.$route.name
              this.$router.push({ name: 'Login' })
            })
        }))
          .catch(() => {
            localStorage.lastUrl = this.$route.name
            this.$router.push({ name: 'Login' })
          })
      },
      keysrt (key) {
        return (a, b) => {
          if (a[key] > b[key]) return 1
          if (a[key] < b[key]) return -1
          return 0
        }
      },
      filterPhones (phones) {
        const workPhone = phones.find(x => x.type === 'work')
        if (workPhone) return workPhone

        return phones[0]
      },
      filterOrganizations (organizations) {
        const correctTitle = organizations.find(x => x.metadata.source.type === 'DOMAIN_PROFILE')
        if (correctTitle && correctTitle.title) return correctTitle.title
        const primaryTitle = organizations.find(x => x.metadata.primary)
        if (primaryTitle && primaryTitle.title) return primaryTitle.title

        return ''
      },
      filterNames (names) {
        const correctNameGroup = names.find(x => x.metadata.source.type === 'DOMAIN_PROFILE')
        if (correctNameGroup) return correctNameGroup

        return {}
      },
      filterEmails (emails) {
        const correctEmailGroup = emails.find(x => x.metadata.source.type === 'DOMAIN_PROFILE')
        if (correctEmailGroup) return correctEmailGroup

        return {}
      },
      saveVibeUser (resLocal) {
        this.vibeUserWrite({
          dark: false,
          callout: '',
          color: 'primary',
          fullName: resLocal.names && resLocal.names.length > 0 ? this.filterNames(resLocal.names).displayName : '',
          firstName: this.filterNames(resLocal.names).givenName,
          lastName: this.filterNames(resLocal.names).familyName,
          phone: resLocal.phoneNumbers && resLocal.phoneNumbers.length > 0 ? this.filterPhones(resLocal.phoneNumbers).canonicalForm : '',
          email: resLocal.emailAddresses && resLocal.emailAddresses.length > 0 ? this.filterEmails(resLocal.emailAddresses).value : '',
          website: 'vibemovesyou.com',
          linkedIn: '',
          companyNickname: 'vibe moves you',
          companyName: 'Operated by NewMotoAsset GmbH',
          companyAddress: 'Richard-Strauss-Straße 32, A-1230 Wien',
          companyUID: 'ATU74116128',
          companyFN: '506831s',
          companyFB: 'Handelsgericht Wien',
          funct: resLocal.organizations && resLocal.organizations.length > 0 ? this.filterOrganizations(resLocal.organizations) : '',
          resourceName: resLocal.resourceName,
        })
      },
      saveVibeDirectory (resDirectoryLocal) {
        this.contacts(resDirectoryLocal.map(x => {
          return {
            dark: false,
            callout: '',
            color: 'primary',
            fullName: x.names && x.names.length > 0 ? this.filterNames(x.names).displayName : '',
            firstName: x.names && x.names.length > 0 ? this.filterNames(x.names).givenName : '',
            lastName: x.names && x.names.length > 0 ? this.filterNames(x.names)?.familyName : '',
            phone: x.phoneNumbers && x.phoneNumbers.length > 0 ? this.filterPhones(x.phoneNumbers).canonicalForm : '',
            email: x.emailAddresses && x.emailAddresses.length > 0 ? this.filterEmails(x.emailAddresses).value : '',
            website: 'vibemovesyou.com',
            linkedIn: '',
            companyName: 'Operated by NewMotoAsset GmbH',
            companyAddress: 'Richard-Strauss-Straße 32, A-1230 Wien',
            companyUID: 'ATU74116128',
            companyFN: '506831s',
            companyFB: 'Handelsgericht Wien',
            funct: x.organizations && x.organizations.length > 0 ? this.filterOrganizations(x.organizations) : '',
            resourceName: x.resourceName,
          }
        }).sort(this.keysrt('firstName')))
      },
    },
  }
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
